import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import ContentBanner from '../../components/Common/ContentBanner';
import Footer from '../../components/App/Footer';
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/shop/shop.jpg';
import ShopCampaign from '../../components/Examples/ShopCampaign';

const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="ショップキャンペーンの事例・サンプル"
		  desc="セレクトショップ、飲食店、美容室等の店舗で使えるキャンペーンについての事例・サンプル集です。"
		  banner={exampleMetaImage}
		  pathname={'/examples/shop'}
		  type={'Article'}
		  shortName="ショップキャンペーンの事例・サンプル"
		  createdAt="2021-03-02"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="ショップ"
		homePageText="Home"
		homePageUrl="/"
		activePageText="ショップ"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	 <ShopCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples